import React from "react";
import {
  DemoProfile,
  DoubleOkSign,
} from "../../../Assets/userProfile/userProfile";

const MessageHistory = ({ message_history }) => {
  console.log("message_history", message_history);
  return (
    <div className="w-full bg-white xl:max-w-[700px]">
      <div className="w-full bg-black px-[20px] py-2">
        <h1 className="font-inter text-[22px] font-medium leading-[41.6px] text-white">
          Message History
        </h1>
      </div>

      {message_history ? (
        <div className="w-full">
          <div className="my-4 h-full max-h-[700px] gap-y-6 overflow-y-scroll px-3 lg:pr-5 xl:mx-[10px] xl:mr-4">
            {message_history?.map((message, i) => {
              const timestamp = message?.date;

              // Convert the timestamp to a Date object
              const date = new Date(timestamp);

              // Format the date (you can customize this format)
              const formattedDate = date.toLocaleString();
              return (
                <div className="mb-4 flex items-center justify-between gap-2">
                  <div className="h-full">
                    <div className="flex items-center gap-x-4">
                      {/* <div>
                        <DemoProfile />
                      </div> */}
                      <div>
                        <h3 className="mb-[10px] font-inter text-[18px] font-medium leading-[29.05px] text-black">
                          Date : {formattedDate}
                        </h3>
                        <h3 className="mb-[10px] font-inter text-[18px] font-medium leading-[29.05px] text-black">
                          Message: {message?.body}
                        </h3>
                        <p className="traking-[0.25px] font-inter text-[17px] font-normal leading-[29.05px]  text-black md:text-[19px]">
                          Message Type: {message?.kind}
                        </p>
                        <p className="traking-[0.25px] font-inter text-[17px] font-normal leading-[29.05px]  text-black md:text-[19px]">
                          Message Read:{" "}
                          {message?.read === true ? "Seen" : "Not Seen"}
                        </p>
                        <p className="traking-[0.25px] font-inter text-[17px] font-normal leading-[29.05px]  text-black md:text-[19px]">
                          Address : {message?.address}
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* <div className="flex h-full flex-col items-end gap-5 gap-y-[11px] text-right">
                    <p className="flex items-center gap-1 p-[3px] text-right font-inter text-[14px] font-medium leading-[21.78px] text-black">
                      <span>
                        <DoubleOkSign />
                      </span>
                      <span>{message?.day}</span>
                    </p>
                    <div className="flex h-[18px] w-[18px] items-center justify-center rounded-full bg-[#0EAB8B]">
                      <p className="font-poppins text-[10px] font-normal text-white">
                        {message?.message_count}
                      </p>
                    </div>
                  </div> */}
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div className="mx-auto w-fit py-[20px]">
          <span className="text-center font-poppins text-[16px] text-gray-400">
            No data is available
          </span>
        </div>
      )}
    </div>
  );
};

export default MessageHistory;
