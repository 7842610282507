import React, { Fragment, useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import MessageHistory from "./MessageHistory";
import CallHistory from "./CallHistory";
import CustomerProfileHeaderNav from "../../smallComponent/CustomerProfileHeaderNav";
import axios from "axios";
import { ENDPOINT } from "../../../App/config/endpoint";
import { AuthContext } from "../../../Provider/AuthProvider";

const CallAndMessageDetails = () => {
  const { token } = useContext(AuthContext);

  const [message, setMessage] = useState([]);
  const [callLog, setCallLog] = useState([]);
  const location = useLocation();
  const currentLocation = location.pathname;

  const segments = currentLocation.split("/")[3];

  const idNumber = decodeURIComponent(segments);
  console.log(idNumber);

  const getCallLogData = () => {
    const apiUrl = `${process.env.REACT_APP_MAIN_URL}${ENDPOINT?.info?.callLogData}`;

    const postData = {
      user_id: idNumber,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    axios
      .post(apiUrl, postData, { headers })
      .then((res) => {
        console.log("crm callog", res);

        const parsedData = JSON.parse(res?.data?.data || []);
        setCallLog(parsedData);
      })
      .catch((err) => {
        console.log("media err: ", err);
      });
  };

  const getMessageData = () => {
    const apiUrl = `${process.env.REACT_APP_MAIN_URL}${ENDPOINT.info.messageData}`;

    const postData = {
      user_id: idNumber,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    axios
      .post(apiUrl, postData, { headers })
      .then((res) => {
        console.log("crm message", res);
        // Parse the stringified JSON data into an actual object/array
        const parsedData = JSON.parse(res?.data?.data?.data);
        setMessage(parsedData);

        // For debugging purposes, you can log the parsed data
        console.log("Parsed Contact Data:", parsedData);
      })
      .catch((err) => {
        console.log("contact err: ", err);
      });
  };

  useEffect(() => {
    getCallLogData();
    getMessageData();
  }, []);
  return (
    <Fragment>
      <div className="mb-[24px]">
        <CustomerProfileHeaderNav />
      </div>

      {/* Gallery and Contact */}
      <div className="mt-[30px] flex flex-col items-center justify-between gap-5 md:items-start lg:flex-row ">
        <MessageHistory message_history={message}></MessageHistory>
        <CallHistory call_history={callLog}></CallHistory>
      </div>
    </Fragment>
  );
};

export default CallAndMessageDetails;
